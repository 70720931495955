import React from 'react'
import PropTypes from 'prop-types'
import { TrackEvent } from '../../helpers/TrackingHelper'
import IconLoaders from '../../icons/IconLoaders'

const TangibleeCTAButton = ({ product }) => {
  return (
    <button
      // using the ID tangiblee needs to change from HIDDEN to FLEX className
      onClick={() =>
        TrackEvent('try_it_on', {
          'Product Title': product.title,
          'Product URL': product.url,
          'Product Image URL': product.small_image.url,
          sku: product.sku,
          price: product.price,
          in_stock: product.in_stock,
        })
      }

      className="hidden mt-3 mx-auto max-w-max rounded-full px-4 py-3 text-sm border bg-gray-200 text-black justify-center items-center lg:absolute -top-[7.5rem] right-[1.4rem] hover:bg-gray-100 hover:shadow-lg hover:border-gray-300 duration-300 group flex-col text-center"
      id="tng-btn"
    >
      <IconLoaders
        type={product?.productType}
        className="size-10 "
        flashing={false}
        iconClassName="size-full"
      />
      <span className="group-hover:text-red-700">Try it On</span>
    </button>
  )
}
TangibleeCTAButton.propTypes = {
  product: PropTypes.object,
}

export default TangibleeCTAButton
