import * as React from 'react'
import PropTypes from 'prop-types'
import MediaRenderer from './MediaRenderer'
import DotSlider from '../sliders/DotSlider'
import useMediaQuery from '../../hooks/useMediaQuery'
import TailwindScreens from '../../helpers/tailwind'
import TangibleeCTAButton from './TangibleeCTAButton'
import isSellable from '../../helpers/product/isSellable'
import loadable from '@loadable/component'
const ImageGalleryPopup = loadable(() => import('../popups/ImageGallery'))
const ImageGallerySlider = loadable(() => import('../sliders/ImageGallerySlider'))
import { fetchTangibleeData, injectTangibleePlaceholders } from '../../helpers/Tangiblee'

const Gallery = ({ product }) => {
  const GalleryRef = React.useRef(null)
  const isMd = useMediaQuery(TailwindScreens.md)
  const isProductSellable = isSellable(product)
  const [tangiblee, setTangiblee] = React.useState(product.tangiblee)
  const [showImage, setShowImage] = React.useState(false)
  const onSlideChangeHandler = React.useCallback(({ slide, type }) => {
    setCurrentImage({ slide, type })
    const event = new CustomEvent('onSlideChange', {
      bubbles: true,
      detail: { slide, type: slide.type || type },
    })
    const target = GalleryRef?.current || window
    target.dispatchEvent(event)
    console.log('dispatchEvent', 'onSlideChange', event)
    // if(slide.type === 'tangiblee') {
    //   console.log('on slide change')
    // }
  }, [])


  const images = React.useMemo(() => {
    const img = (product.media_gallery || [])
      .concat(product.certificates || [])
      .filter(img => img.gatsbyImageDataMock !== null)
    injectTangibleePlaceholders(tangiblee, img)
    return img
  }, [product.media_gallery, product.certificates, tangiblee])
  const [currentImage, setCurrentImage] = React.useState({ slide: images[0], type: 'image' })
  const dotSliderImages = isMd ? [currentImage.slide] : images

  React.useEffect(() => {
    fetchTangibleeData(product?.sku).then(setTangiblee)
  }, [product?.sku])

  const onImageClick = React.useCallback(image => setShowImage(images.indexOf(image)), [images])

  return (
    <>
      {/* Popup with the magnified image */}
      {isMd && showImage !== false ? (
        <ImageGalleryPopup
          showImage={showImage}
          setShowImage={setShowImage}
          images={images}
          product={product}
        />
      ) : null}
      {/* Product Details Images */}
      <div className="justify-start w-full md:row-span-5 md:row-start-1 relative">
        <div ref={GalleryRef} className="p-2 sm:p-4 cursor-zoom-in w-full aspect-square">
          <DotSlider dots={dotSliderImages.length} showArrowsMobile={true}>
            {dotSliderImages.map((image) => (
              <MediaRenderer
                key={image.id || image.url}
                image={image}
                productInfo={product}
                isSellable={isProductSellable}
                onClick={() => onImageClick(image)}
              />
            ))}
          </DotSlider>
        </div>
        {product.in_stock && ( // NOTE - in_stock won't change on runtime, it's build time data
          <div className="relative ">
            <div className="hidden md:block h-[7.25rem] lg:h-20">
              {isMd && (
                // NOTE - the above is div is always render to prevent layout shift, on runtime isMD can change
                // and it loads as false (mobile first approach) so the below component is not rendered on desktop
                // and it would cause layout shift unless the above div is always rendered
                <ImageGallerySlider
                  videos={product?.videos?.videos}
                  slides={images}
                  product={product}
                  tangiblee={tangiblee}
                  onSlideChange={onSlideChangeHandler}
                />
              )}
            </div>
            <TangibleeCTAButton product={product} />
          </div>
        )}
      </div>
    </>
  )
}
Gallery.propTypes = {
  images: PropTypes.array,
  product: PropTypes.object,
  onImageClick: PropTypes.func,
}

export default Gallery
