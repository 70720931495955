export async function fetchTangibleeData(sku) {
  try {
    // Prepare SKU and URL
    sku = sku.toLowerCase()
    const url = `https://api.tangiblee.com/api/tngimpr?ids=${sku}&domain=www.grayandsons.com&activeLocale=en`

    // Fetch data
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    // Check if the response is OK
    if (!response.ok) {
      console.error(`Failed to fetch data for SKU ${sku}. HTTP Status: ${response.status}`)
      return null
    }

    // Parse the JSON response
    const data = await response.json()

    // Extract relevant parts of the response
    const exists = data.exists?.[sku]
    if (!exists) return null

    const product = data.products?.[sku]
    if (!product || !product.approved) return null

    let cta_urls = data.cta_urls?.[sku]
    if (!cta_urls) return null
    if (!Array.isArray(cta_urls)) cta_urls = [cta_urls]
    if (product.category === 'Brooches' || product.category === 'Cuff Links') return [cta_urls[0]]
    return [cta_urls[0], cta_urls[1 % cta_urls.length]]
    // Return CTA URL or null if not available
    // return data.cta_urls?.[sku] || null
  } catch (error) {
    return null
  }
}

export function injectTangibleePlaceholders(tangiblee, images) {
  if (!tangiblee) return images
  if (!Array.isArray(tangiblee)) {
    tangiblee = [tangiblee]
  }
  // images.splice(1, 0, {
  //   url: tangiblee[0],
  //   name: 'Tangiblee 6',
  //   type: 'tangiblee',
  //   id: 'tangiblee-6',
  //   index: 5,
  // })
  // inject tangiblee images, as 2nd and 3rd images, and last 3 images
  images.splice(1, 0, {
    url: tangiblee[0],
    name: 'Tangiblee 1',
    type: 'tangiblee',
    id: 'tangiblee-1',
    index: 0,
  })
  if (tangiblee.length > 1) {
    images.splice(2, 0, {
      url: tangiblee[0],
      name: 'Tangiblee 2',
      type: 'tangiblee',
      id: 'tangiblee-2',
      index: 1,
    })
  }
  // images.push({
  //   url: tangiblee,
  //   name: 'Tangiblee 3',
  //   type: 'tangiblee',
  //   id: 'tangiblee-3',
  //   index: 2,
  // })
  // images.push({
  //   url: tangiblee,
  //   name: 'Tangiblee 4',
  //   type: 'tangiblee',
  //   id: 'tangiblee-4',
  //   index: 3,
  // })
  // images.push({
  //   url: tangiblee,
  //   name: 'Tangiblee 5',
  //   type: 'tangiblee',
  //   id: 'tangiblee-5',
  //   index: 4,
  // })
  return images
}
