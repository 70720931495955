import * as React from 'react'
import PropTypes from 'prop-types'
import TangibleePlaceholder from './TangibleePlaceholder'
import { ProductImage } from '../CategoryImage'
import YouTube, { autoplayYouTubeVideoUrl } from '../homepage/YouTube'
import { srcSizes } from '../../common/gatsbyImageData'

const MediaRenderer = ({ image, productInfo, isSellable, onClick }) => {
  if (image.type === 'video') {
    return (
      <YouTube
        src={autoplayYouTubeVideoUrl(image.slide.url)}
        title={productInfo.title}
        className="w-full h-full aspect-square"
      />
    )
  } else if (image?.slide?.type === 'tangiblee' || image.type === 'tangiblee') {
    return <TangibleePlaceholder image={image.slide || image} type='full' />
  } else {
    return (
      <ProductImage
        image={image.slide || image}
        product={productInfo}
        imgStyle={{ objectFit: 'contain' }}
        sizes="(min-width: 768px) 50vw, 100vw"
        loading="eager"
        tags
        isSellable={isSellable}
        mockSizes={srcSizes}
        onClick={onClick}
      />
    )
  }
}
MediaRenderer.propTypes = {
  image: PropTypes.object,
  productInfo: PropTypes.object,
  isSellable: PropTypes.bool,
  onClick: PropTypes.func,
}

export default MediaRenderer
