import React, { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { IconLoading } from '../IconsCheckout'

// eslint-disable-next-line react/display-name
const TangibleePlaceholderFull = forwardRef(({ id }, ref) => {
  return (
    <div
      ref={ref}
      id={id}
      className="flex bg-white w-full h-full aspect-square shrink-0 text-center justify-center items-center"
    >
      <IconLoading className={'w-2/3 aspect-square h-auto p-10 animate-spin text-gray-300'} />
    </div>
  )
})
TangibleePlaceholderFull.propTypes = {
  id: PropTypes.string,
  image: PropTypes.object,
}

// eslint-disable-next-line react/display-name
const TangibleePlaceholderSmall = forwardRef(({ id, image }, ref) => {
  return (
    <div id={id} ref={ref} className="w-full aspect-square">
      <img
        src={image.url}
        loading="lazy"
        alt={image.name}
        className="relative w-full h-full contains"
      />
    </div>
  )
})
TangibleePlaceholderSmall.propTypes = {
  id: PropTypes.string,
  image: PropTypes.object,
}

const TangibleePlaceholder = ({ image, type = 'full' }) => {
  const ref = useRef()
  const id = `${image.id}-${type}`
  const Component = type === 'full' ? TangibleePlaceholderFull : TangibleePlaceholderSmall
  useEffect(() => {
    if (ref.current) {
      const event = new CustomEvent('onTangibleePlaceholderRecreated', {
        bubbles: true,
        detail: {
          ...image,
          id,
          element: ref.current,
        },
      })
      ref.current.dispatchEvent(event)
    }
  }, [id, ref?.current])
  return <Component id={id} image={image} ref={ref} />
}
const TangibleePlaceholderPure = React.memo(TangibleePlaceholder, () => true)
TangibleePlaceholder.propTypes = {
  id: PropTypes.string,
  image: PropTypes.object,
  type: PropTypes.oneOf(['full', 'small']),
}

export default TangibleePlaceholderPure
