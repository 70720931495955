const axios = require('axios')
const { BackendError } = require('../../common/errors')
const { finalPrice } = require('../finalPrice')

// Use environment variables for Affirm configuration
const AFFIRM_API_KEY = process.env.AFFIRM_API_KEY
const AFFIRM_SECRET_KEY = process.env.AFFIRM_SECRET_KEY
const AFFIRM_API_URL = process.env.AFFIRM_API_URL
const MAXIMUM_AFFIRM_AMOUNT = 30000
const MINIMUM_AFFIRM_AMOUNT = 1000

const getPaymentIntent = async paymentInentId => {
  const response = await axios.get(AFFIRM_API_URL + '/api/v2/checkout/' + paymentInentId, {
    auth: {
      username: AFFIRM_API_KEY,
      password: AFFIRM_SECRET_KEY,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const paymentIntent = response.data
  // console.log('Payment intent:', JSON.stringify(paymentIntent, null, 2))
  const result = {
    status: 'processing',
    amount: paymentIntent.amount / 100,
    amount_received: 0,
  }
  if (paymentIntent.checkout_status === 'confirmed') {
    const response2 = await axios.post(
      AFFIRM_API_URL + '/api/v1/transactions',
      {
        transaction_id: paymentInentId,
        order_id: paymentIntent.order_id,
      },
      {
        auth: {
          username: AFFIRM_API_KEY,
          password: AFFIRM_SECRET_KEY,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    result.paymentInfo = response2.data
    result.status = 'paid'
    result.amount = paymentIntent.amount / 100
    result.amount_received = paymentIntent.amount_received / 100
  } else {
    result.status = paymentIntent.checkout_status
  }
  return result
}

const paymentIntent = async state => {
  try {
    // Extract relevant information from the state object
    const { cart, extra, checkout } = state
    const items = cart.map(item => ({
      display_name: item.name,
      sku: item.sku,
      unit_price: Math.round(100 * finalPrice(item)),
      qty: item.qty,
      item_url: item.url,
      item_image_url: item.image.url,
      categories: [[item.category]],
    }))

    // Construct the payload for Affirm payment intent creation
    const discounts = extra.coupon
      ? {
        COUPON: {
          discount_amount: Math.round(
            100 * (extra.coupon.discount + (extra.coupon.free_shipping ? extra.shipping : 0))
          ),
          discount_display_name: extra.coupon.description || extra.coupon.code,
        },
      }
      : null
    const payload = {
      merchant: {
        public_api_key: process.env.AFFIRM_API_KEY,
        user_confirmation_url: `${process.env.SITE_URL}/thank-you-shopping?p=affirm&s=done&uid=${extra.order_uid}&id=${extra.order_id}`,
        user_cancel_url: process.env.SITE_URL + '/checkout/#payment',
        user_confirmation_url_action: 'GET',
      },
      shipping: {
        name: {
          full: checkout.shipping_name + ' ' + checkout.shipping_last_name,
        },
        address: {
          line1: checkout.shipping_address,
          line2: checkout.shipping_address2,
          city: checkout.shipping_city,
          state: checkout.shipping_state,
          zipcode: checkout.shipping_zip,
          country: checkout.shipping_country,
        },
        email: checkout.email,
        phone_number: checkout.phone,
      },
      billing: {
        name: {
          full: checkout.billing_name + ' ' + checkout.billing_last_name,
        },
        address: {
          line1: checkout.billing_address,
          line2: checkout.billing_address2,
          city: checkout.billing_city,
          state: checkout.billing_state,
          zipcode: checkout.billing_zip,
          country: checkout.billing_country,
        },
        email: checkout.email,
        phone_number: checkout.phone,
      },
      items: items,
      order_id: `Order #${extra.order_id}`,
      shipping_amount: Math.round(100 * (extra.shipping + extra.handling_fee)),
      tax_amount: Math.round(100 * extra.tax),
      total: Math.round(100 * extra.total),
    }
    if (discounts) {
      payload.discounts = discounts
    }

    // console.log(payload)
    // Making the request to Affirm for payment intent creation
    const response = await axios.post(AFFIRM_API_URL + '/api/v2/checkout/direct', payload, {
      auth: {
        username: AFFIRM_API_KEY,
        password: AFFIRM_SECRET_KEY,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const affirmResponse = response.data

    return {
      intent: affirmResponse.checkout_id,
      url: affirmResponse.redirect_url,
    }
  } catch (error) {
    console.error('Error creating Affirm payment intent:', error)
    throw new BackendError('Error creating Affirm payment intent: ' + error.message)
  }
}

const surcharge = total => total * 0.08

module.exports = {
  paymentIntent,
  surcharge,
  getPaymentIntent,
  MAXIMUM_AFFIRM_AMOUNT,
  MINIMUM_AFFIRM_AMOUNT,
}
